<script>
    import VueToastr from "vue-toastr";
    
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import { BASE_URL } from '../../../baseconstant';
    
    /**
     * Orders component
     */
    export default {
      components: { Layout, PageHeader, VueToastr },
      page: {
        title: "Tiers",
        meta: [
          {
            name: "description",
            content: appConfig.description,
          },
        ],
      },
      data() {
        return {
          title: "Tiers",
          isBusy: false,
          items: [
            {
              text: "App",
            },
            {
              text: "Tiers",
              active: true,
            },
          ],
          tiersData: [],
          totalRows: 1,
          currentPage: 1,
          perPage: 10,
          pageOptions: [10, 25, 50, 100],
          filter: null,
          filterOn: [],
          sortBy: "age",
          sortDesc: false,
          fields: [
            {
              key: "index",
              label: "S/N",
            },
            {
              key: "name",
              label: "Name",
            },
            {
              key: "transaction_limit",
              label: "Transaction Limit",
            },
            {
              key: "maximum_balance",
              label: "Maximum Balance",
            },
            "action",
          ],
          tier: {
            name: '',
            transaction_limit: '',
            maximum_balance: '',
          },
          selectedTier: null,
          allSelected: false,
          indeterminate: false,
        };
      },
      middleware: "authentication",
      computed: {
        /**
         * Total no. of records
         */
        rows() {
          return this.tiersData.length;
        },
      },
      mounted() {
        // Set the initial number of items
        this.totalRows = this.items.length;
        this.fetchData();
      },
      methods: {
        fetchData() {
            this.isBusy =  true
            this.axios.get(BASE_URL+'/admin/tier/all')
            .then((res) => {
                // console.log(res.data.data);
                this.tiersData = res.data.data
            })
            .catch((err) => {
                // console.log(err.response?.data?.error);
                this.$refs.mytoast.Add({
                msg: err.response?.data?.error,
                clickClose: false,
                timeout: 5000,
                position: "toast-top-right",
                type: "error",
              });
            })
            .finally(() => {
                this.isBusy =  false
            });
        },
        addTier() {
            this.isBusy =  true
            this.axios.post(BASE_URL+'/admin/tier/create', this.tier)
            .then(() => {
                // console.log(res.data.data);
                this.fetchData();

                this.$refs.mytoast.Add({
                msg: "Tier Created Successfully",
                clickClose: false,
                timeout: 5000,
                position: "toast-top-right",
                type: "success",
              });
            })
            .catch((err) => {
                // console.log(err.response);
                this.$refs.mytoast.Add({
                msg: err.response?.data?.error,
                clickClose: false,
                timeout: 5000,
                position: "toast-top-right",
                type: "error",
              });
            })
            .finally(() => {
                this.isBusy =  false
            });
        },
        editTier() {
            this.isBusy =  true

            this.axios.put(BASE_URL+'/admin/tier/update/' 
            + this.selectedTier, this.tier)
            .then(() => {
                this.fetchData();

                this.$refs.mytoast.Add({
                msg: "Tier Edited Successfully",
                clickClose: false,
                timeout: 5000,
                position: "toast-top-right",
                type: "success",
              })
            })
            .catch((err) => {
                this.$refs.mytoast.Add({
                msg: err.response.data.details,
                clickClose: false,
                timeout: 5000,
                position: "toast-top-right",
                type: "error",
              });
            })
            .finally(() => {
                this.isBusy =  false
            });
        },
        emptyVal() {
            this.tier.name = null;
            this.tier.maximum_balance = null;
            this.tier.transaction_limit = null;
        },
        getTierInfo(item) {
            this.selectedTier = item.id
            this.tier.name = item.name
            this.tier.maximum_balance = item.maximum_balance
            this.tier.transaction_limit = item.transaction_limit
        },
  
        /**
         * Search the table data with search input
         */
        onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length;
          this.currentPage = 1;
        },
      },
    
    };
    </script>
    
    <template>
      <Layout>
        <PageHeader :title="title" :items="items" />
        <vue-toastr ref="mytoast"></vue-toastr>
        <!-- ::START ADD Role Modal -->    
        <b-modal id="modal-add-tier" title="Add Role" title-class="font-18" hide-footer>
              <label for="" class="m-2">Name: </label>
              <input type="text" v-model="tier.name" id="horizontal-firstname-input" placeholder="Enter Tier" class="m-2 form-control">
              
              <label for="" class="m-2">Transaction limit: </label>
              <input type="number" v-model="tier.transaction_limit" id="horizontal-firstname-input" placeholder="10000" class="m-2 form-control">
              
              <label for="" class="m-2">Maximum Balance: </label>
              <input type="number" v-model="tier.maximum_balance" id="horizontal-firstname-input" placeholder="10000" class="m-2 form-control">
              
              <div class="modal-footer">
                  <button @click="addTier(), $bvModal.hide('modal-add-tier')" type="button" class="btn btn-primary">
                      Save changes
                  </button>
                  <b-button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                      @click="$bvModal.hide('modal-add-tier')"
                      >
                      Close
                  </b-button>
              </div>
          </b-modal>
        <!-- ::END ADD Role Modal -->

        <!-- ::START EDIT Role Modal -->    
        <b-modal id="modal-edit-tier" title="Edit Role" title-class="font-18" hide-footer>
          <label for="" class="m-2">Name: </label>
              <input type="text" v-model="tier.name" id="horizontal-firstname-input" placeholder="Enter Tier" class="m-2 form-control">
              
              <label for="" class="m-2">Transaction limit: </label>
              <input type="number" v-model="tier.transaction_limit" id="horizontal-firstname-input" placeholder="10000" class="m-2 form-control">
              
              <label for="" class="m-2">Maximum Balance: </label>
              <input type="number" v-model="tier.maximum_balance" id="horizontal-firstname-input" placeholder="10000" class="m-2 form-control">
          
          <!-- <textarea v-model="role.features" name="features" id="horizontal-firstname-input" cols="55" rows="10" class="m-2 form-control"></textarea> -->
          <div class="modal-footer">
              <button @click="editTier(), $bvModal.hide('modal-edit-tier')" type="button" class="btn btn-primary">
                  Save changes
              </button>
              <b-button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                  @click="$bvModal.hide('modal-edit-tier')"
                  >
                  Close
              </b-button>
          </div>
        </b-modal>
        <!-- ::END EDIT Role Modal -->

        <div class="row">
          <div class="col-12">
            <button
            type="button"
            class="btn btn-primary mb-3 brand-primary"
            v-b-modal.modal-add-tier
            @click="emptyVal()"
          ><i class="mdi mdi-plus me-1"></i> Add New Tier
          </button>
            <div class="table table-centered datatable dt-responsive nowrap table-card-list dataTable no-footer dtr-inline">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center fw-normal">
                      Show&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                      ></b-form-select
                      >&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div
                    id="tickets-table_filter"
                    class="dataTables_filter text-md-end"
                  >
                    <label class="d-inline-flex align-items-center fw-normal">
                      Search:
                      <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ms-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              
              <b-table
                :busy="isBusy"
                table-class="table table-centered datatable table-card-list"
                thead-tr-class="bg-transparent"
                :items="tiersData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                 @filtered="onFiltered"
                show-empty
              >
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                    </div>
                </template>
                <template #empty="scope">
                    <p class="text-center p-3">{{ scope.emptyText }}</p>
                </template>
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>
    
                <template v-slot:cell(name)="data">
                  <a href="javascript:void(0)">{{data.item.name.replace('-', ' ')}}</a>
                </template>
                <template v-slot:cell(transaction_limit)="data">
                  ₦{{data.item.transaction_limit.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
                </template>
                <template v-slot:cell(maximum_balance)="data">
                  ₦{{data.item.maximum_balance.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}
                </template>
  
                
                <template v-slot:cell(action)="{ item }">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <a
                        class="px-2 text-primary"
                        v-b-tooltip.hover
                        title="Edit"
                        @click="getTierInfo(item)"
                        v-b-modal.modal-edit-tier
                      >
                        <i class="uil uil-pen font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </template>

    <style scoped>
  /* Hide the spinner controls for input type number */
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>
    